import { map } from 'rxjs';

import { ChangeDetectionStrategy, Component } from '@angular/core';

import { MerchantAdminFeature } from '@bp/shared/domains/permissions';

import { IdentityFacade } from '@bp/admins-shared/domains/identity';
import { IntroFacade } from '@bp/admins-shared/pages/intro/core';

@Component({
	selector: 'bp-reset-password-page',
	templateUrl: './reset-password-page.component.html',
	styleUrls: [ './reset-password-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false,
})
export class ResetPasswordPageComponent {

	isResetExpiredPassword$ = this.identityFacade.incompleteIdentity$.pipe(
		map(identity => !!identity?.hasPermission(MerchantAdminFeature.resetExpiredPassword)),
	);

	constructor(
		public introFacade: IntroFacade,
		public identityFacade: IdentityFacade,
	) { }

}
