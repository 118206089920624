import { combineReducers, createFeature } from '@ngrx/store';

import { refillFeature } from './refill/refill.feature';
import { creditPackageFeature } from './entity/credit-package.feature';

const reducer = combineReducers({
	[creditPackageFeature.name]: creditPackageFeature.reducer,
	[refillFeature.name]: refillFeature.reducer,
});

export const creditPackagesStoreFeature = createFeature({
	name: '[domain]:credit-package',
	reducer,
});
