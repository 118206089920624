import { ChangeDetectionStrategy, Component } from '@angular/core';

import { BaseLoginFormComponent } from './base-login-form';

@Component({
	selector: 'bp-login-form',
	templateUrl: './login-form.component.html',
	styleUrls: [ './login-form.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false,
})
export class LoginFormComponent extends BaseLoginFormComponent {
}
