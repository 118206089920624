import { inject, Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { requestRefill } from './refill.actions';
import { selectPending, selectError } from './refill.selectors';

@Injectable({
	providedIn: 'root',
})
export class RefillFacade {

	private readonly __store$ = inject(Store);

	pending$ = this.__store$.select(selectPending);

	error$ = this.__store$.select(selectError);

	requestRefill(): void {
		this.__store$.dispatch(requestRefill());
	}

}
