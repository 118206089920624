import { createFeature, createReducer, on } from '@ngrx/store';

import { BpError } from '@bp/frontend/models/core';

import { requestRefill } from './refill.actions';
import { requestRefillFailure, requestRefillSuccess } from './refill-api.actions';

export interface IState {
	pending: boolean;
	error: BpError | null;
}

export const initialState: IState = {
	pending: false,
	error: null,
};

export const refillFeature = createFeature({
	name: 'refill',
	reducer: createReducer(
		initialState,

		on(
			requestRefill,
			(state): IState => ({
				...state,
				pending: true,
				error: null,
			}),
		),

		on(
			requestRefillSuccess,
			requestRefillFailure,
			(state): IState => ({
				...state,
				pending: false,
			}),
		),

		on(
			requestRefillFailure,
			(state, { error }): IState => ({
				...state,
				error,
			}),
		),
	),
});
