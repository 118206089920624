import { BehaviorSubject } from 'rxjs';

import { inject, Injectable } from '@angular/core';

import { EnvironmentService } from '@bp/frontend/services/environment';
import { ICookiesUsageConsent } from '@bp/frontend/features/analytics/models';

@Injectable({ providedIn: 'root' })
export class CookiesUsageConsentService {

	private readonly __environment = inject(EnvironmentService);

	private readonly __cookiesUsageConsent$ = new BehaviorSubject<ICookiesUsageConsent | null>(
		this.__environment.isProduction
			? null
			: { marketing: true, preferences: true, statistics: true },
	);

	consent$ = this.__cookiesUsageConsent$.asObservable();

	constructor() {
		if (this.__environment.isProduction)
			this.__setConsentAccordingToCMP();
	}

	private __setConsentAccordingToCMP(): void {
		this.__setConsentWhenWasAlreadyGiven();

		this.__setConsentWhenGiven();
	}

	private __setConsentWhenWasAlreadyGiven(): void {
		const existingConsent = window.Termly?.getConsentState();

		if (existingConsent && this.__isAnythingExceptEssentialAllowed(existingConsent)) {
			this.__cookiesUsageConsent$.next(
				this.__mapConsentToCookiesUsageConsent(existingConsent),
			);
		}
	}

	private __setConsentWhenGiven(): void {
		window.Termly?.on('consent', (consent: ITermlyCookiesUsageConsent) => {
			void this.__cookiesUsageConsent$.next(
				this.__mapConsentToCookiesUsageConsent(consent.consentState),
			);
		});
	}

	private __mapConsentToCookiesUsageConsent(consent: TermlyConsentState | null): ICookiesUsageConsent {
		return {
			marketing: !!consent?.advertising || !!consent?.social_networking,
			preferences: !!consent?.performance,
			statistics: !!consent?.analytics,
		};
	}

	private __isAnythingExceptEssentialAllowed(consent: TermlyConsentState): boolean {
		return Object.entries(consent)
			.some(([ category, isAllowed ]) => category !== 'essential' && isAllowed);
	}
}

declare global {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface Window {
		// https://support.termly.io/en/articles/9674733-getting-consent-state-and-handling-consent-changes-with-termly
		// eslint-disable-next-line @typescript-eslint/naming-convention
		Termly?: {
			getConsentState: () => TermlyConsentState;

			on: (event: string, callback: (data: any) => void) => void;
		};
	}

	type TermlyConsentCategory = 'advertising' | 'analytics' | 'essential' | 'performance' | 'social_networking' | 'unclassified';

	type TermlyConsentState = {[K in TermlyConsentCategory]: boolean };

	interface ITermlyCookiesUsageConsent {
		categories: TermlyConsentCategory[];
		consentState: TermlyConsentState | null;
	}
}
