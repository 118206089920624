import { Component, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

import { ensureFormGroupConfig } from '@bp/shared/typings';
import { Validators } from '@bp/shared/features/validation/models';

import { FormBaseComponent } from '@bp/frontend/components/core';

import type { IResetPasswordOtpVerificationApiRequest } from '@bp/admins-shared/domains/identity/models';

@Component({
	selector: 'bp-otp-verification-form',
	templateUrl: './otp-verification-form.component.html',
	styleUrls: [ './otp-verification-form.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false,
})
export class OtpVerificationFormComponent extends FormBaseComponent<IResetPasswordOtpVerificationApiRequest> {

	@Output() readonly tryAnotherWay = new EventEmitter<void>();

	override onSubmitShowInvalidInputsToast = false;

	constructor() {
		super();

		this.form = this._formBuilder.group(
			ensureFormGroupConfig<IResetPasswordOtpVerificationApiRequest>({
				code: [ null, { validators: Validators.required }],
			}),
		);
	}

}
