import { inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { Store } from '@ngrx/store';

import { resetState, load, refresh } from './credit-package.actions';
import {
	selectError, selectCurrentCreditPackage, selectCurrentCreditPackageLoading
} from './credit-package.selectors';

@Injectable({
	providedIn: 'root',
})
export class CreditPackageFacade {

	private readonly __store$ = inject(Store);

	entity$ = this.__store$.select(selectCurrentCreditPackage);

	entity = toSignal(this.entity$);

	loading$ = this.__store$.select(selectCurrentCreditPackageLoading);

	error$ = this.__store$.select(selectError);

	load(): void {
		this.__store$.dispatch(load());
	}

	refresh(): void {
		this.__store$.dispatch(refresh());
	}

	resetState(): void {
		this.__store$.dispatch(resetState());
	}

}
