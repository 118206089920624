import { createAction } from '@ngrx/store';

import { createVerboseAction } from '@bp/frontend/ngrx';

export const ACTIONS_SCOPE = '[Domain][Credit Package]';

export const load = createAction(`${ ACTIONS_SCOPE } Load`);

export const refresh = createVerboseAction(`${ ACTIONS_SCOPE } Refresh`);

export const resetState = createAction(`${ ACTIONS_SCOPE } Reset State`);
