import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { IntroFacade } from '@bp/admins-shared/pages/intro/core';

@Component({
	selector: 'bp-login-after-invite',
	templateUrl: './login-after-invite-page.component.html',
	styleUrls: [ './login-after-invite-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false,
})
export class LoginAfterInvitePageComponent {

	protected readonly _introFacade = inject(IntroFacade);

}
