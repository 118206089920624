import { createFeature, createReducer, on } from '@ngrx/store';

import { isEqual } from '@bp/shared/utilities/core';

import { BpError } from '@bp/frontend/models/core';

import { MerchantCreditPackage } from '@bp/merchant-admin-shared/shared/domains/credit-packages';

import { resetState } from './credit-package.actions';
import { loadFailure, loadSuccess, refreshFailure, refreshSuccess } from './credit-package-api.actions';

export interface IState {
	entity: MerchantCreditPackage | null;

	loading: boolean;

	error: BpError | null;
}

export const initialState: IState = {
	entity: null,
	loading: true,
	error: null,
};

export const creditPackageFeature = createFeature({
	name: 'entity',
	reducer: createReducer(
		initialState,

		on(resetState, (): IState => initialState),

		on(
			loadSuccess,
			refreshSuccess,
			(state, { result }): IState => ({
				...state,
				entity: isEqual(result, state.entity) ? state.entity : result,
				loading: false,
				error: null,
			}),
		),

		on(
			loadFailure,
			refreshFailure,
			(state, { error }): IState => ({
				...state,
				error,
				entity: null,
				loading: false,
			}),
		),
	),
});
