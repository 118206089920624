import { firstValueFrom } from 'rxjs';

import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule, NgZone } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { NavigationActionTiming, RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';

import { SharedComponentsCoreModule } from '@bp/frontend/components/core';
import { SharedFeaturesValidationModule } from '@bp/frontend/features/validation';
import { SharedPipesModule } from '@bp/frontend/pipes';
import { sharedReducer } from '@bp/frontend/state';
import { SharedFeaturesAnalyticsModule } from '@bp/frontend/features/analytics';
import { SharedDomainsCrmUsersCurrentCrmUserStateModule } from '@bp/frontend/domains/crm/users/+current-crm-user-state';
import { SharedDomainsCrmOrganizationsCurrentCrmOrganizationStateModule } from '@bp/frontend/domains/crm/organizations/+current-crm-organization-state';
import { SharedServicesModule } from '@bp/frontend/services/core';
import { GlobalApiMockModule } from '@bp/frontend/api-mocking-global';
import { FirebaseAppConfig, FIREBASE_APP_CONFIG } from '@bp/frontend/features/firebase';

import { AdminsSharedDomainsIdentityRootModule } from '@bp/admins-shared/domains/identity';
import { AdminsSharedFeaturesLayoutRootModule } from '@bp/admins-shared/features/layout';
import { AdminsSharedFeaturesNotificationsHubRootModule } from '@bp/admins-shared/features/notifications-hub';
import { AdminsSharedPagesIdentityModule } from '@bp/admins-shared/pages/identity';
import { AdminsSharedBridgerPspsRootModule } from '@bp/admins-shared/domains/bridger-psps';
import { AdminsSharedSubscriptionPlansRootModule } from '@bp/admins-shared/domains/subscription-plans';

import { MerchantAdminFrontendFeaturesAnnouncementsRootModule } from '@bp/merchant-admin-shared/frontend/features/announcements';
import { MerchantAdminSharedFrontendDomainsCurrentOrganizationRootModule } from '@bp/merchant-admin-shared/frontend/domains/current-organization';
import { MerchantAdminSharedFrontendDomainsCurrentOrganizationSubscriptionRootModule } from '@bp/merchant-admin-shared/frontend/domains/current-organization-subscription';
import { CurrentMemberRootModule } from '@bp/merchant-admin-shared/frontend/domains/current-member';
import { MerchantAdminSharedFrontendDomainsCheckoutsRootModule } from '@bp/merchant-admin-shared/frontend/domains/checkouts';
import {
	MerchantAdminSharedFrontendDomainsCreditPackagesRootModule
} from '@bp/merchant-admin-shared/frontend/domains/credit-packages';

import { AppStartupService } from './services';

@NgModule({
	imports: [
		// Redux related
		StoreModule.forRoot(sharedReducer, {
			runtimeChecks: {
				strictActionImmutability: false,
				// StrictActionSerializability: false,
				strictStateImmutability: false,

				/*
                 * StrictStateSerializability: false
                 * We dont use the built-in immutability check because
                 * It freezes the whole moment structure
                 * So we utilize custom immutabilityCheckMetaReducer
                 */
			},
		}),
		StoreRouterConnectingModule.forRoot({
			routerState: RouterState.Minimal,
			navigationActionTiming: NavigationActionTiming.PostActivation,
		}),
		EffectsModule.forRoot([]),
		SharedPipesModule.forRoot(),
		SharedServicesModule.forRoot(),
		SharedComponentsCoreModule.forRoot(),
		SharedFeaturesValidationModule.forRoot(),
		SharedFeaturesAnalyticsModule.forRoot(),
		SharedDomainsCrmOrganizationsCurrentCrmOrganizationStateModule.forRoot(),
		SharedDomainsCrmUsersCurrentCrmUserStateModule.forRoot(),
		AdminsSharedSubscriptionPlansRootModule,
		AdminsSharedDomainsIdentityRootModule,
		AdminsSharedBridgerPspsRootModule,
		AdminsSharedFeaturesLayoutRootModule,
		AdminsSharedFeaturesNotificationsHubRootModule,
		AdminsSharedPagesIdentityModule,

		MerchantAdminFrontendFeaturesAnnouncementsRootModule,
		MerchantAdminSharedFrontendDomainsCreditPackagesRootModule,
		MerchantAdminSharedFrontendDomainsCurrentOrganizationRootModule,
		MerchantAdminSharedFrontendDomainsCurrentOrganizationSubscriptionRootModule,

		MerchantAdminSharedFrontendDomainsCheckoutsRootModule,
		CurrentMemberRootModule,
		GlobalApiMockModule,
	],
	providers: [
		{
			provide: FIREBASE_APP_CONFIG,
			useValue: {
				appId: '1:977741303368:web:b8e53980b491e281',
				enablePersistence: true,
			} satisfies FirebaseAppConfig,
		},
		provideHttpClient(
			withInterceptorsFromDi(),
		),
	],
})
export class SharedMerchantAdminAppModule {

	constructor(
		private readonly __appStartupService: AppStartupService,
		private readonly __zone: NgZone,
	) {
		void this.__whenAppIsStableInitStartupLogic();

	}

	private async __whenAppIsStableInitStartupLogic(): Promise<void> {
		await firstValueFrom(this.__zone.onStable);

		this.__appStartupService.init();
	}

}
