import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { IdentityFacade } from '@bp/admins-shared/domains/identity';

@Component({
	selector: 'bp-accept-invite-page',
	templateUrl: './accept-invite-page.component.html',
	styleUrls: [ './accept-invite-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false,
})
export class AcceptInvitePageComponent {

	protected readonly _identityFacade = inject(IdentityFacade);

	constructor() {
		this._identityFacade.acceptInvite();
	}

}
