import { createAction, props } from '@ngrx/store';

import { ErrorActionPayload } from '@bp/frontend/models/common';

import { ACTIONS_SCOPE } from './refill.actions';

const scope = `[API]${ ACTIONS_SCOPE }`;

export const requestRefillSuccess = createAction(`${ scope } Request Refill Success`);

export const requestRefillFailure = createAction(
	`${ scope } Request Refill Failure`,
	props<ErrorActionPayload>(),
);
