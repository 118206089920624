import { ChangeDetectionStrategy, Component } from '@angular/core';

import { DTO } from '@bp/shared/models/metadata';

import { ControlsSectionScheme, FormMetadataEntityBaseComponent } from '@bp/frontend/components/metadata';

import { SupportRequestCreateModel } from '@bp/merchant-admin-shared/shared/domains/support-requests';

@Component({
	selector: 'bp-request-support-form',
	templateUrl: './request-support-form.component.html',
	styleUrls: [ './request-support-form.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false,
})
export class RequestSupportFormComponent extends FormMetadataEntityBaseComponent<SupportRequestCreateModel> {

	override onSubmitShowInvalidInputsToast = false;

	override metadata = SupportRequestCreateModel.getClassMetadata();

	protected _sectionScheme: ControlsSectionScheme<SupportRequestCreateModel> = [
		[ 'duration' ],
		[ 'message' ],
	];

	constructor() {
		super();

		this._setupForm({
			message: null,
			duration: null,
		});
	}

	override factory = (dto?: DTO<SupportRequestCreateModel>): SupportRequestCreateModel => new SupportRequestCreateModel(dto);

}
