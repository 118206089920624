import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IntroFacade } from '@bp/admins-shared/pages/intro/core';

@Component({
	selector: 'bp-session-expired-page',
	templateUrl: './session-expired-page.component.html',
	styleUrls: [ './session-expired-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false,
})
export class SessionExpiredPageComponent {

	constructor(public introFacade: IntroFacade) {
	}

}
