import { Component, ChangeDetectionStrategy, EventEmitter, Output, Input } from '@angular/core';

import { Validators } from '@bp/shared/features/validation/models';
import { ensureFormGroupConfig } from '@bp/shared/typings';

import { FormBaseComponent } from '@bp/frontend/components/core';

import {
	SecurityQuestion, ISecurityQuestionAnswerApiRequest
} from '@bp/admins-shared/domains/identity/models';

@Component({
	selector: 'bp-provide-security-question-answer-form',
	templateUrl: './provide-security-question-answer-form.component.html',
	styleUrls: [ './provide-security-question-answer-form.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false,
})
export class ProvideSecurityQuestionAnswerFormComponent extends FormBaseComponent<ISecurityQuestionAnswerApiRequest> {

	@Input() identitySecurityQuestions!: SecurityQuestion[] | null;

	@Input() hasAnotherWay?: boolean | null;

	@Output() readonly tryAnotherWay = new EventEmitter<void>();

	override onSubmitShowInvalidInputsToast = false;

	constructor() {
		super();

		this.form = this._formBuilder.group(ensureFormGroupConfig<ISecurityQuestionAnswerApiRequest>({
			id: [ null, { validators: Validators.required }],
			answer: [ null, { validators: Validators.required }],
		}));
	}
}
