import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { DTO } from '@bp/shared/models/metadata';
import { NEST_CREDIT_PACKAGES_PATH_SEGMENT } from '@bp/shared/domains/credit-packages';
import { NEST_PATH_SEGMENT } from '@bp/shared/models/core';

import {
	MerchantCreditPackage, NEST_CURRENT_CREDIT_PACKAGE_PATH_SEGMENT, NEST_REFILL_PATH_SEGMENT
} from '@bp/merchant-admin-shared/shared/domains/credit-packages';

@Injectable({
	providedIn: 'root',
})
export class CreditPackagesApiService {

	private readonly __http = inject(HttpClient);

	private readonly _namespace = `${ NEST_PATH_SEGMENT }/${ NEST_CREDIT_PACKAGES_PATH_SEGMENT }`;

	getCurrent(): Observable<MerchantCreditPackage | null> {
		return this.__http
			.get<DTO<MerchantCreditPackage> | null>(`${ this._namespace }/${ NEST_CURRENT_CREDIT_PACKAGE_PATH_SEGMENT }`)
			.pipe(map(merchantCreditPackage => merchantCreditPackage
				? new MerchantCreditPackage(merchantCreditPackage)
				: null));
	}

	requestRefill(): Observable<void> {
		return this.__http.post<void>(`${ this._namespace }/${ NEST_REFILL_PATH_SEGMENT }`, {});
	}

}
