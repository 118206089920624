import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

import { IconSize } from '@bp/shared/models/core';

import { OnChanges } from '@bp/frontend/models/core';

@Component({
	selector: 'bp-share-btn[url]',
	templateUrl: './share-btn.component.html',
	styleUrls: [ './share-btn.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false,
})
export class ShareBtnComponent implements OnChanges, ShareData {

	@Input() url!: string;

	@Input() title?: string;

	@Input() text?: string;

	@Input() iconSize: IconSize = 'md';

	@Input() mode: 'compact' | 'full' = 'compact';

	@Input() color?: ThemePalette;

	protected get _isFullMode(): boolean {
		return this.mode === 'full';
	}

	protected get _isCompactMode(): boolean {
		return this.mode === 'compact';
	}

	protected _canShare = false;

	ngOnChanges(): void {
		this._canShare = this.__canShare();
	}

	protected _share(): void {
		void navigator.share(this);
	}

	private __canShare(): boolean {
		return 'canShare' in navigator
			? navigator.canShare(this)
			: false;
	}
}
