import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IdentityFacade } from '@bp/admins-shared/domains/identity';
import { IntroFacade } from '@bp/admins-shared/pages/intro/core';

@Component({
	selector: 'bp-send-reset-authenticator-app-link-page',
	templateUrl: './send-reset-authenticator-app-link-page.component.html',
	styleUrls: [ './send-reset-authenticator-app-link-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false,
})
export class SendResetAuthenticatorAppLinkPageComponent {

	constructor(
		public introFacade: IntroFacade,
		public identityFacade: IdentityFacade,
	) { }

}
