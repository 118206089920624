import { Component, OnInit, ContentChild, HostBinding, HostListener, Input, ChangeDetectionStrategy } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { Destroyable, takeUntilDestroyed } from '@bp/frontend/models/common';
import { SlidingStepRoute } from '@bp/frontend/features/sliding-step-routes/models';

import { SLIDING_STEP_ROUTES_ANIMATION_TRIGGER } from './sliding-step-routes-animation-trigger';

@Component({
	selector: 'bp-sliding-step-routes',
	templateUrl: './sliding-step-routes.component.html',
	styleUrls: [ './sliding-step-routes.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ SLIDING_STEP_ROUTES_ANIMATION_TRIGGER ],
	standalone: false,
})
export class SlidingStepRoutesComponent extends Destroyable implements OnInit {

	@Input()
	steps?: SlidingStepRoute[] | null;

	@ContentChild(RouterOutlet, { static: true })
	routerOutlet?: RouterOutlet;

	@HostBinding('@translateRoutesRightLeft')
	animationState: 'left' | 'right' | 'void' = 'void';

	private _currentStep?: SlidingStepRoute;

	ngOnInit(): void {
		this.routerOutlet!.activateEvents
			.pipe(takeUntilDestroyed(this))
			.subscribe(() => void this._setNextAnimationState(
				this.routerOutlet!.activatedRouteData['step'],
			));
	}

	@HostListener('@translateRoutesRightLeft.done')
	onAnimationDone(): void {
		this.animationState = 'void';
	}

	private _setNextAnimationState(nextStep?: SlidingStepRoute): void {
		if (!nextStep)
			throw new Error('nextStep is required');

		const currentStepIndex = this.steps!.indexOf(this._currentStep!);
		const nextStepIndex = this.steps!.indexOf(nextStep);

		this._currentStep = nextStep;

		this.animationState = currentStepIndex === nextStepIndex
			? 'void'
			: (currentStepIndex > nextStepIndex ? 'right' : 'left');
	}
}
