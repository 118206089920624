import { createAction, props } from '@ngrx/store';

import { ErrorActionPayload, ResultActionPayload } from '@bp/frontend/models/common';
import { createVerboseAction } from '@bp/frontend/ngrx';

import { MerchantCreditPackage } from '@bp/merchant-admin-shared/shared/domains/credit-packages';

import { ACTIONS_SCOPE } from './credit-package.actions';

const scope = `[API]${ ACTIONS_SCOPE }`;

export const loadSuccess = createAction(
	`${ scope } Load Success`,
	props<ResultActionPayload<MerchantCreditPackage | null>>(),
);

export const loadFailure = createAction(
	`${ scope } Load Failure`,
	props<ErrorActionPayload>(),
);

export const refreshSuccess = createVerboseAction(
	`${ scope } Refresh Success`,
	props<ResultActionPayload<MerchantCreditPackage | null>>(),
);

export const refreshFailure = createVerboseAction(`${ scope } Refresh Failure`, props<ErrorActionPayload>());
