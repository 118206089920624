import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'bp-general-error-page',
	templateUrl: './general-error-page.component.html',
	styleUrls: [ './general-error-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'flex-center' },
	standalone: false,
})
export class GeneralErrorPageComponent {

}
