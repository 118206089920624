import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ApiMockPluginsManagerService } from '@bp/frontend/services/core';

import { creditPackagesStoreFeature } from './state/credit-packages.store-feature';
import { CreditPackageEffects } from './state/entity/credit-package.effects';
import { RefillEffects } from './state/refill/refill.effects';

@NgModule({
	imports: [
		StoreModule.forFeature(creditPackagesStoreFeature),
		EffectsModule.forFeature([ CreditPackageEffects, RefillEffects ]),
	],
})
export class MerchantAdminSharedFrontendDomainsCreditPackagesRootModule {

	constructor(private readonly _apiMocksManagerService: ApiMockPluginsManagerService) {
		void this._apiMocksManagerService.register(
			MerchantAdminSharedFrontendDomainsCreditPackagesRootModule,
			async () => import('./api-mock-plugin/credit-packages-api-mock-plugin'),
		);
	}

}
