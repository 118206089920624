import { catchError, map, switchMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { of, tap } from 'rxjs';

import { inject, Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ResponseStatusCode } from '@bp/shared/models/core';

import { reportJsErrorIfAny } from '@bp/frontend/rxjs';
import { BpError } from '@bp/frontend/models/core';

import { CreditPackagesApiService } from '../../services';

import { requestRefillFailure, requestRefillSuccess } from './refill-api.actions';
import { requestRefill } from './refill.actions';

@Injectable()
export class RefillEffects {

	private readonly __creditPackagesApiService = inject(CreditPackagesApiService);

	private readonly __actions$ = inject(Actions);

	private readonly __toaster = inject(ToastrService);

	requestRefill$ = createEffect(() => this.__actions$.pipe(
		ofType(requestRefill),
		switchMap(() => this.__creditPackagesApiService
			.requestRefill()
			.pipe(
				map(requestRefillSuccess),
				reportJsErrorIfAny,
				catchError((error: unknown) => {
					const bpError = BpError.fromUnknown(error);

					return of(bpError.status === ResponseStatusCode.RateLimited
						? requestRefillSuccess()
						: requestRefillFailure({ error: bpError }));
				}),
			)),
	));

	showToastOnRequestRefillSuccess$ = createEffect(
		() => this.__actions$.pipe(
			ofType(requestRefillSuccess),
			tap(() => this.__toaster.info('Refill request has been sent')),
		),
		{ dispatch: false },
	);

	showToastOnRequestRefillFailure$ = createEffect(
		() => this.__actions$.pipe(
			ofType(requestRefillFailure),
			tap(({ error }) => this.__toaster.error(error.message)),
		),
		{ dispatch: false },
	);

}
