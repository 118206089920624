import { ChangeDetectionStrategy, Component } from '@angular/core';

import { BaseOtpOnLoginFormComponent } from './base-otp-on-login-form.component';

@Component({
	selector: 'bp-otp-on-login-form',
	templateUrl: './otp-on-login-form.component.html',
	styleUrls: [ './otp-on-login-form.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false,
})
export class OtpOnLoginFormComponent extends BaseOtpOnLoginFormComponent {}
