import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import { inject, Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { apiNullableResult } from '@bp/frontend/models/common';

import { NotificationsHubService } from '@bp/admins-shared/features/notifications-hub';

import { CurrentOrganizationFacade } from '@bp/merchant-admin-shared/frontend/domains/current-organization';

import { CreditPackagesApiService } from '../../services';

import { loadFailure, loadSuccess, refreshFailure, refreshSuccess } from './credit-package-api.actions';
import { load, refresh } from './credit-package.actions';
import { CreditPackageFacade } from './credit-package.facade';

@Injectable()
export class CreditPackageEffects {

	private readonly __creditPackageFacade = inject(CreditPackageFacade);

	private readonly __creditPackagesApiService = inject(CreditPackagesApiService);

	private readonly __notificationsHubService = inject(NotificationsHubService);

	private readonly __currentOrganizationFacade = inject(CurrentOrganizationFacade);

	private readonly __actions$ = inject(Actions);

	load$ = createEffect(() => this.__actions$.pipe(
		ofType(load),
		switchMap(() => this.__creditPackagesApiService
			.getCurrent()
			.pipe(apiNullableResult(loadSuccess, loadFailure))),
	));

	notifyUserOnceWhenCreditPackageIsAlmostDepleted$ = createEffect(() => this.__actions$.pipe(
		ofType(loadSuccess, refreshSuccess),
		filter(({ result }) => !!result),
		filter(({ result }) => !!result?.isLast
			&& result.status.isActive
			&& result.used > 0.8 * result.bought),
		// eslint-disable-next-line rxjs/no-unsafe-first
		take(1),
		tap(() => {
			this.__notificationsHubService.next({
				text: 'Your data tank is almost depleted',
				duplicateAsToast: true,
			});
		}),
	), { dispatch: false });

	notifyUserOnceWhenCreditPackageIsDepletedOrExpired$ = createEffect(() => this.__actions$.pipe(
		ofType(loadSuccess, refreshSuccess),
		filter(({ result }) => !!result && (result.status.isDepleted || result.status.isExpired)),
		// eslint-disable-next-line rxjs/no-unsafe-first
		take(1),
		tap(({ result }) => {
			this.__notificationsHubService.next({
				text: `Your data tank is ${ result!.status.isExpired ? 'expired' : 'depleted' }`,
				duplicateAsToast: true,
			});
		}),
	), { dispatch: false });

	refreshOnCurrentOrganizationRefresh$ = createEffect(() => this.__currentOrganizationFacade.onRefresh$
		.pipe(map(() => refresh())));

	refresh$ = createEffect(() => this.__actions$.pipe(
		ofType(refresh),
		filter(() => !!this.__creditPackageFacade.entity()),
		switchMap(() => this.__creditPackagesApiService
			.getCurrent()
			.pipe(apiNullableResult(refreshSuccess, refreshFailure))),
	));

}
